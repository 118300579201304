import ImageGallery from 'react-image-gallery'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'

export default function PhotoGalleryApp() {

    const { obituary } = useSelector(state => state.obituaryReducer)

    return (
        <>
            {obituary ?
                <>
                    <div className="w-full max-w-screen-lg m-auto">
                        <ImageGallery
                            items={obituary.gallery_photos.map((galleryPhoto, count) => ({
                                original: galleryPhoto,
                                thumbnail: galleryPhoto,
                                originalAlt: `${obituary.display_name} photo gallery image ${count + 1} of ${obituary.gallery_photos.length}`,
                                thumbnailAlt: `${obituary.display_name} photo gallery thumbnail ${count + 1} of ${obituary.gallery_photos.length}`,
                                thumbnailLoading: 'lazy',
                                loading: 'lazy'
                            }))}
                            showPlayButton={false}
                            showBullets={obituary.gallery_photos.length <= 25}
                            lazyLoad={true}
                            showIndex={true}
                        />
                    </div>
                </>
                : <Skeleton height={25} count={3} baseColor={"#444"} highlightColor={"#666"} />
            }
        </>
    )
}