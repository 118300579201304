import Button from "@/components/common/ui/buttons/Button";
import React from "react";
import BookCoverImage from "@/public_obituary/book/BookCoverImage";
import {useSelector} from "react-redux";

export default function BookBlock({onViewBook}) {
    const {
        obituary
    } = useSelector(state => state.obituaryReducer);

    const scrollToElement = (elementId) => {
        const targetElement = document.getElementById(elementId);
        if (targetElement) {
            targetElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    return (
        <div className="rounded-lg bg-white shadow mb-10 overflow-hidden">
            <div className="md:flex gap-4 md:gap-10">
                <div className="flex w-full md:w-2/5 bg-gray-300 justify-center items-center">
                    <div className="w-full max-w-[400px] p-16 py-5">
                        <BookCoverImage />
                    </div>
                </div>

                <div className="flex-1 p-6 flex-row content-center">
                    <div className="mb-5">
                        <div className="flex-1 mb-2">
                            <div className="uppercase tracking-widest text-slate-500">
                                Celebrating The Life Of
                            </div>
                            <div className="text-2xl font-serif">{obituary?.display_name}</div>
                        </div>

                        <div className="flex items-center gap-3">
                            <Button
                                onClick={onViewBook}
                                label={"View Book"}
                                color={"white"}
                            />

                            <a
                                href={obituary?.book_sales_url}
                                target="_blank"
                                className="shadow-sm border border-transparent text-white bg-blue-600 px-4 py-2 text-sm rounded-md inline-flex items-center justify-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                            >
                                Buy Book
                            </a>
                        </div>
                    </div>

                    <p className="text-lg mb-5">
                        A beautifully bound keepsake book filled with memories and condolences from family and
                        friends.
                    </p>

                    <div className="sm:flex gap-3 border-t border-gray-100 pt-5">
                        <div className="flex-1 mb-2">
                            <h3 className="font-semibold text-slate-800 mb-2">Share Your Memory</h3>

                            <p className="text-sm max-w-xs">
                                Contribute your memory to the online guestbook to be featured in this unique
                                keepsake
                                book.
                            </p>
                        </div>

                        <div className="content-center">
                            <button
                                onClick={() => scrollToElement('tukios-obituary-guestbook')}
                                className="shadow-sm border border-transparent text-white bg-slate-600 px-4 py-2 text-sm rounded-md inline-flex items-center justify-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                            >
                                Share Your Memory
                            </button>
                        </div>
                    </div>


                </div>
            </div>

            <style dangerouslySetInnerHTML={{
                __html: `
                      html { scroll-behavior: smooth; }
                      #tukios-obituary-guestbook { scroll-margin-top: 100px; }
                    `
            }}></style>

        </div>
    );
}
