import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useEffect, useState } from "react"
import { PulseLoader } from "react-spinners"
import { useSelector } from "react-redux"
import {t} from '@/components/common/Helpers'


export default function CampaignDonationForm({ methods, setSuccess }) {
    const stripe = useStripe()
    const elements = useElements()
    const [isConfirming, setIsConfirming] = useState(false)
    const [loading, setLoading] = useState(true)

    const {
        obituaryCampaignDonationIntent,
    } = useSelector(state => state.obituaryCampaignDonationIntentReducer)

    const handleSubmit = async (event) => {
        event.preventDefault()

        try {
            setIsConfirming(true)
            stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: `${window.location.href}`,
                    receipt_email: methods.getValues().email,
                },
                redirect: 'if_required',
            }).then((result) => {
                if (result.error) {
                    setSuccess(false)
                    alert(result.error?.message ?? 'Error. Please Check All Forms and Try Again')
                } else {
                    setSuccess(true)
                }

            })
        } finally {
            setIsConfirming(false)
        }

    }

    useEffect(() => {
        if (elements) {
            elements.getElement('payment').on('ready', function () {
                setLoading(false)
            })
        }
    }, [elements])



    return (
        <>
            {stripe && elements && obituaryCampaignDonationIntent ? (
                <>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <PaymentElement />
                        </div>

                        {!loading &&
                            <button type="submit"
                                className="px-5 py-2 border border-transparent
                                font-medium rounded-full shadow-sm text-white bg-blue-500 hover:bg-blue-600
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
                                { t.trans('campaign.complete') }
                            </button>
                        }
                    </form>

                    <div className={isConfirming ? '' : 'hidden'}>
                        {t.trans('campaign.doing')}
                        <PulseLoader color={"#ccc"} size={12} margin={10} />
                    </div>
                </>
            ) : <>{t.trans('campaign.error')}</>}
        </>
    )
};
