import {useState} from 'react'
import {t} from '@/components/common/Helpers'
import CampaignDonation from './CampaignDonation'
import Notice from '@/components/common/ui/Notice'

export default function CampaignModal({campaign, closeModal}) {

    const [success, setSuccess] = useState(false)

    return (
        <>
            {success ? (
                <Notice type="success" title={t.trans('campaign.title')} description={t.trans('campaign.processed')} />
            ): (
                <CampaignDonation setSuccess={setSuccess} campaign={campaign} />
            )}
        </>
    )
}
