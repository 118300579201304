import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { obituaryCampaignEntity, obituaryCampaignDonationEntity, obituaryCampaignDonationIntentEntity } from '../store/root'
import { usePrevious } from '@/components/common/Helpers'
import CampaignBlock from "./CampaignBlock"

import ModalStandalone from "../../components/common/ui/ModalStandalone"

import CampaignModal from "./CampaignModal"

export default function CampaignApp() {

    const dispatch = useDispatch()

    const service = obituaryCampaignEntity.services

    const {
        obituaryCampaignList,
        obituaryCampaignFetchAllLoading,
    } = useSelector(state => state.obituaryCampaignReducer)

    const {
        obituary
    } = useSelector(state => state.obituaryReducer)

    const [modalOpen, setModalOpen] = useState(false)
    const [theCampaign, setTheCampaign] = useState(0)

    const previous = usePrevious({ obituary })

    const refresh = () => dispatch(service.findAll())

    useEffect(() => {
        if (obituary?.id && previous?.obituary?.id != obituary?.id) {
            refresh()
        }
    }, [obituary])

    const click = (cam) => {
        setTheCampaign(cam)
    }

    useEffect(() => {
        if (theCampaign) {
            setModalOpen(true)
        }
    }, [theCampaign])

    const clickClose = () => {
        setModalOpen(false)
    }

    useEffect(() => {
        if (!modalOpen) {
            setTheCampaign(null)
            dispatch(obituaryCampaignDonationEntity.services.clearAll())
            dispatch(obituaryCampaignDonationIntentEntity.services.clearAll())
            refresh()
        }
    }, [modalOpen])

    return (
        <>
            {obituary && obituaryCampaignList && !obituaryCampaignFetchAllLoading && (
                <>
                    {modalOpen &&
                        <ModalStandalone
                            cancelButton={false}
                            open={modalOpen}
                            onCancel={clickClose}
                            onClose={clickClose}
                            Text={() => (<CampaignModal campaign={theCampaign} closeModal={clickClose} />)}
                        />
                    }
                    <div>
                        {obituaryCampaignList.map((campaign) => (
                            <CampaignBlock onClick={click} key={campaign.id} campaign={campaign} showCrowdfundingGoal={obituary.show_crowdfunding_goal} />
                        ))}
                    </div>
                </>
            )}
        </>
    )
}
