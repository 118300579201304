import { useState } from "react"
import { classNames, dateformat, formatCurrency, t, locale } from '@/components/common/Helpers'
import { CheckCircleIcon } from "@heroicons/react/24/outline"
import moment from 'moment'

export default function CampaignBlock({ campaign, onClick = null, showCrowdfundingGoal }) {

    const [showDonors, setShowDonors] = useState(false)

    const styles = {
        width: `${campaign.complete}%`,
    }

    const columnCount = (count) => {
        /* columns-1 columns-2 columns-3 columns-4 */
        let cols = Math.ceil(count / 8)
        return `columns-${cols < 4 ? cols : 4}`
    }

    const makeDonationDate = (created_at) => {
        const date = moment(created_at).toDate()
        return date.toLocaleString(locale, { month: 'short', day: '2-digit', year: 'numeric' })
    }

    const donationWords = () => {
        let word = 'donation'
        let suffix = campaign.donations.length > 1 ? 's' : ''
        word += suffix
        return t.trans('campaign.' + word)
    }

    return (
        <div className="mb-8 gap-6">

            <div className="">
                <h4 className="font-bold leading-none tracking-tight text-gray-900 text-2xl">
                    {campaign.name}
                </h4>
                <p className="mb-4 mt-2 text-sm text-gray-600 space-y-4" dangerouslySetInnerHTML={{ __html: campaign.description }}></p>
            </div>
            <div className="rounded-lg bg-white shadow p-6">
                <div className="md:flex gap-4 md:gap-10">
                    <div className="flex-1 mb-4">
                        <div className="mb-3">
                            <p className="flex items-center">
                                <span
                                    className="text-2xl font-bold text-gray-900 font-bold mr-2">{formatCurrency(campaign.donations_total ?? 0, campaign.currency, 0)}
                                </span>
                                {showCrowdfundingGoal && <span className="text-gray-400 text-sm font-medium">{t.trans('campaign.raised-1')} {formatCurrency(campaign.goal_public, campaign.currency, 0)} {t.trans('campaign.raised-2')}</span>}
                            </p>
                        </div>
                        <div className="w-full bg-gray-100 rounded-full h-2 dark:bg-gray-700 mb-3">
                            <div className="bg-green-600 h-2 rounded-full" style={styles}></div>
                        </div>


                        {campaign.donations.length > 0 &&
                            <>
                                <p className="text-gray-400 text-sm underline cursor-pointer mb-2" onClick={() => setShowDonors(!showDonors)}>{campaign.donations.length} {donationWords()}</p>
                            </>
                        }
                    </div>
                    <div className="pt-6 w-full md:w-1/5">
                        {campaign.goal_public - campaign.donations_total <= 0 ? (
                            <div className="font-bold py-2
                             rounded-full text-green-700 bg-green-50
                            w-full text-center flex justify-center">
                                <CheckCircleIcon className="w-6 h-6 mr-2" />
                                <span>{t.trans('campaign.goal-met')}</span></div>
                        ) : (
                            <button onClick={() => {
                                if (onClick) {
                                    onClick(campaign)
                                }
                            }}
                                className="campaign-button inline-block font-bold px-10 py-2 border border-transparent
                                rounded-full shadow-sm text-white bg-blue-500 hover:bg-blue-600
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 w-full text-center">
                                {t.trans('campaign.donate-now')}
                            </button>
                        )

                        }

                    </div>
                </div>
                {showDonors &&
                    <ol className={classNames("gap-4 list-decimal py-6 border-t border-gray-200", columnCount(campaign.donations.length))}>
                        {campaign.donations.map(donation => (
                            <li key={donation.id} className="text-sm ml-4 break-inside-avoid-column">
                                <p className="font-bold">{donation.name}</p>
                                <p className="text-gray-400">{formatCurrency(donation.amount, donation.currency, 0)} - {makeDonationDate(donation.created_at)}</p>
                            </li>
                        ))}
                    </ol>
                }



            </div>

        </div>

    )
}
