import { useSelector } from 'react-redux'
import ExitIntentModal from './ExitIntentModal'

export default function ExitIntent() {
    const { obituary } = useSelector((state) => state.obituaryReducer)

    return (
        obituary && <ExitIntentModal />
    )
}
