import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from './store/root'
import ErrorBoundary from '../components/common/ErrorBoundary'
import App from './App'
import GuestbookApp from './guestbook/GuestbookApp'
import PhotoGalleryApp from './photo_gallery/PhotoGalleryApp'
import CampaignApp from "./campaign/CampaignApp"
import BookApp from "./book/BookApp";
import ExitIntent from './exit_intent/ExitIntent'

const app = document.getElementById('tukios-obituary-app')

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <App data={app} />
        </Provider>
    </ErrorBoundary>,
    app
)

const guestbookApp = document.getElementById('tukios-obituary-guestbook')

if (guestbookApp) {
    const disableObituaryPosts = guestbookApp.hasAttribute('data-disable-posts') && !(/^(false|0|off|no)$/i).test(guestbookApp.getAttribute('data-disable-posts'))

    ReactDOM.render(
        <ErrorBoundary>
            <Provider store={store}>
                <GuestbookApp disablePosts={disableObituaryPosts} />
            </Provider>
        </ErrorBoundary>,
        guestbookApp
    )
}

const gallery = document.getElementById('tukios-obituary-gallery')

if (gallery) {
    ReactDOM.render(
        <ErrorBoundary>
            <Provider store={store}>
                <PhotoGalleryApp />
            </Provider>
        </ErrorBoundary>,
        gallery
    )
}

const campaignApp = document.getElementById('tukios-obituary-campaigns')

if (campaignApp) {

    ReactDOM.render(
        <>
            <Provider store={store}>
                <CampaignApp />
            </Provider>
        </>,
        campaignApp
    )
}

const bookApp = document.getElementById('tukios-obituary-book')

if (bookApp) {
    ReactDOM.render(
        <>
            <Provider store={store}>
                <BookApp />
            </Provider>
        </>,
        bookApp
    )
}

const exitIntent = document.getElementById('tukios-obituary-exit-intent-modal')

if (exitIntent) {
    ReactDOM.render(
        <ErrorBoundary>
            <Provider store={store}>
                <ExitIntent />
            </Provider>
        </ErrorBoundary>,
        exitIntent
    )
}
