import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { obituaryEntity } from './store/root'
import 'react-loading-skeleton/dist/skeleton.css'

export default function App({ data }) {

    const dispatch = useDispatch()

    useEffect(() => {
        if (data.getAttribute('data-organization-id')) {
            axios.defaults.headers.common['X-Organization-Id'] = data.getAttribute('data-organization-id')
        }

        dispatch(obituaryEntity.services.findById(data.getAttribute('data-obituary-id')))
    }, [])

    return (<></>)
}