import { XMarkIcon } from "@heroicons/react/24/solid"
import { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useExitIntent } from "use-exit-intent"

export default function ExitIntentModal() {
    const { obituary } = useSelector((state) => state.obituaryReducer)
    const { registerHandler, unsubscribe } = useExitIntent({
        cookie: {
            daysToExpire: obituary.exit_intent?.lifetime || 1,
            key: "tukios-obituary-exit-intent"
        },
        desktop: {
            triggerOnIdle: false,
            useBeforeUnload: false,
            triggerOnMouseLeave: true,
            delayInSecondsToTrigger: 0
        },
        mobile: {
            triggerOnIdle: true,
            delayInSecondsToTrigger: obituary.exit_intent?.idle || 3
        }
    })

    const [overflowSetting, setOverflowSetting] = useState("scroll")
    const [hideModal, setHideModal] = useState(true)

    useEffect(() => {
        document.body.style.overflow = `${overflowSetting}`
    }, [overflowSetting])

    registerHandler({
        id: "tukios-obituary-exit-intent",
        handler: () => {
            setOverflowSetting("hidden")
            setHideModal(false)
            unsubscribe()
        },
    })

    const handleModalClose = () => {
        setOverflowSetting("auto")
        setHideModal(true)
    }

    return (
        <div className={`exit-intent-modal fixed h-screen w-screen bg-gray-950 bg-opacity-75 z-100 ${hideModal ? "hidden" : "block"}`}>
            <div className="flex justify-center items-center w-full h-full p-5 sm:p-0">
                <div className="shadow-2xl shadow-gray-950 relative">
                    <button
                        type="button"
                        className="absolute -top-8 -right-2 rounded-md text-gray-400 hover:text-white flex items-center"
                        onClick={handleModalClose}
                    >
                        <span className="text-sm">Close</span>
                        <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                    <a href={obituary.exit_intent.url} target="_blank" rel="noreferrer">
                        <img src={obituary.exit_intent.asset_image} className="max-w-full max-h-[calc(100vh-200px)]" alt="Custom call to action image" />
                    </a>
                </div>
            </div>
        </div>
    )
}
