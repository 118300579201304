import { Elements } from "@stripe/react-stripe-js"
import { PulseLoader } from "react-spinners"
import CampaignDonationForm from "./CampaignDonationForm"
import CampaignPaymentIntentForm from "./CampaignPaymentIntentForm"
import { loadStripe } from "@stripe/stripe-js"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { locale, money } from '@/components/common/Helpers'
import { obituaryCampaignDonationIntentEntity } from "../store/root"
import { useEffect, useState } from 'react'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const publicKey = import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY

export default function CampaignDonation({ campaign, setSuccess }) {

    const [stripePromise, setStripePromise] = useState(null)
    const service = obituaryCampaignDonationIntentEntity.services

    const {
        obituaryCampaignDonationIntent,
        obituaryCampaignDonationIntentCreateLoading,
        obituaryCampaignDonationIntentCreateError,
    } = useSelector(state => state.obituaryCampaignDonationIntentReducer)

    const dispatch = useDispatch()

    const methods = useForm({
        defaultValues: {
            name: null,
            amount: 50,
            email: null,
            is_anonymous: false,
            currency: campaign.currency,
            custom_tip: 0,
            tip: 15,
        }
    })

    methods.watch(['tip', 'amount', 'custom_tip', 'currency'])

    const preProcessData = (data) => {
        let tipMoney
        if (!data.tip || data.tip == 0) {
            data.tip = null
            if (!data.custom_tip || data.custom_tip == 0) {
                data.custom_tip = null
            } else {
                tipMoney = money(data.custom_tip, data.currency)
                data.tip = `${tipMoney.dollars()}.${tipMoney.cents()}`
            }
        } else {
            const tip = data.tip
            const tipAmount = tip ? (parseInt(tip) / 100 + 1) : 1
            tipMoney = money(tipAmount, currency)
            const amount = money(data.amount, currency)
            tipMoney = amount.multiply(tipMoney).subtract(amount)
            data.tip = `${tipMoney.dollars()}.${tipMoney.cents()}`
        }
        return data
    }

    const donate = (data) => {
        data = preProcessData(data)
        dispatch(service.create(data, { params: { campaign: campaign.id }, return: true }))
    }

    const appearance = {
        theme: 'none',

        rules: {
            '.Input': {
                borderBottom: '1px solid #ccc',
                backgroundColor: 'rgb(249 250 251)',
                borderRadius: '0px',
                paddingTop: '0.5rem',
                paddingRight: '0.75rem',
                paddingBottom: '0.5rem',
                paddingLeft: '0.75rem',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                marginBottom: '1px',
            },
            '.Input:focus': {
                borderBottom: '2px solid #2563E9',
                marginBottom: '0px',
                outline: '0px',
            }
        }
    }

    useEffect(async () => {
        const p = await loadStripe(publicKey, { locale })
        setStripePromise(p)
    }, [])

    return (
        <>
            <div className="flex md:divide-x text-left items-center">
                <div className="hidden md:block md:w-7/12 md:pr-6">
                    <img src={`${import.meta.env.VITE_APP_URL}/img/crowdfunding/hugging.png`} className="w-full mb-8" />
                    <h3 className="text-xl font-bold uppercase mb-4">{campaign.name}</h3>
                    <p className="text-sm text-gray-500 space-y-4" dangerouslySetInnerHTML={{ __html: campaign.description }} ></p>
                </div>
                <div className="w-full md:w-5/12 md:pl-6">
                    {obituaryCampaignDonationIntentCreateLoading ? (
                        <PulseLoader color={"#ccc"} size={12} margin={10} />
                    ) : (
                        <>
                            {obituaryCampaignDonationIntent ? (
                                <>
                                    {stripePromise ? (
                                        <Elements options={{ clientSecret: obituaryCampaignDonationIntent.intent.client_secret, appearance: appearance }} stripe={stripePromise}>
                                            <CampaignDonationForm
                                                methods={methods}
                                                setSuccess={setSuccess}
                                            />
                                        </Elements>
                                    ) : (
                                        <>
                                            <PulseLoader color={"#ccc"} size={12} margin={10} />
                                        </>
                                    )}
                                </>
                            ) : (
                                <CampaignPaymentIntentForm onSubmit={donate} methods={methods} errors={obituaryCampaignDonationIntentCreateError} campaign={campaign} />
                            )}
                        </>
                    )
                    }

                </div>
            </div>
        </>
    )
}
