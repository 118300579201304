import {Controller, FormProvider, useFormContext} from 'react-hook-form'
import Input from '@/components/common/ui/forms/Input'
import {classNames, filled, money, t} from '@/components/common/Helpers'
import Toggle from '@/components/common/ui/forms/Toggle'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {CurrencyType} from '@/components/common/enum/CurrencyType'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'


export default function CampaignPaymentIntentForm({campaign, methods, onSubmit, errors}) {

    const [anonymous, setAnonymous] = useState(false)
    const [hasCustomTip, setHasCustomTip] = useState(false)
    const [previousTipAmount, setPreviousTipAmount] = useState(15)

    const required = true
    const validations = {}

    useEffect(() => {
        methods.setValue('is_anonymous', anonymous)
    }, [anonymous])

    /**
     * 1. click tip amount, it calculates percentage, and sets that as the box.
     * 2.
     */
    useEffect(() => {
        const value = methods.getValues('tip')

        if (!value || value == 0) {
            methods.setValue('custom_tip', null)
        }

    }, [methods.getValues('tip')])

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 30,
            label: '30%',
        },
    ];

    const getTotal = () => {
        const amount = methods.getValues('amount')

        if (!amount) {
            return ''
        }

        const currency = methods.getValues('currency')

        let value = money(amount, currency)

        if (hasCustomTip) {
            const tip = methods.getValues('custom_tip') ?? 0
            const tipMoney = money(tip, currency)
            value = value.add(tipMoney)
        } else {
            const tip = methods.getValues('tip')
            const tipAmount = tip ? (parseInt(tip) / 100 + 1) : 1
            const tipMoney = money(tipAmount, currency)
            value = value.multiply(tipMoney)
        }

        return value.format()
    }

    const tipAmount = () => {
        const currency = methods.getValues('currency')
        return money(methods.getValues('amount') * (methods.getValues('tip') / 100), currency).format()
    }

    const suggestedAmounts = [25, 50, 75, 100, 250, 500]


    const useCustomTip = () => {
        const useCustom = !hasCustomTip
        setHasCustomTip(useCustom)
        if (useCustom) {
            setPreviousTipAmount(methods.getValues('tip'))
            methods.setValue('tip', 0)
        } else {
            methods.setValue('tip', previousTipAmount)
            methods.setValue('custom_tip', 0)
        }
    }

    const useSetAmount = (dollars) => {
        methods.setValue('amount', dollars)
    }

    const MoneyRows = () => {
        return (
            <div className={'grid grid-cols-3 gap-2 text-center'}>
                {suggestedAmounts.map((suggestedAmount, i) => (
                    <div key={i} onClick={() => useSetAmount(suggestedAmount)} className={classNames('cursor-pointer border-2 rounded-lg p-2', methods.getValues('amount') == suggestedAmount ? 'border-blue-500' : 'border-gray-300')}>{`$${suggestedAmount}`}</div>
                ))}
            </div>
        )
    }

    const useCustomAmount = (dollars = null) => {
        if (filled(dollars)) {
            methods.setValue('amount', dollars)
        }
    }

    const CurrencySelect = () => {
        const { register, formState: { errors }, setValue } = useFormContext()
        return (
            <div className="absolute inset-y-0 right-0 flex items-center">
                <label htmlFor="currency" className="sr-only">
                    Currency
                </label>
                <select
                    {...register('currency', { required: true })}
                    id="currency"
                    defaultValue={methods.getValues('currency') ?? 'USD'}
                    name="currency"
                    className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                >
                    <option value="USD">USD</option>
                    <option value="CAD">CAD</option>
                </select>
            </div>
        )
    }

    return (
        <FormProvider {...methods}>
            <form id="campaign-donate-form" onSubmit={methods.handleSubmit(onSubmit)}>
                <div>
                    <div className="text-center text-xl font-bold uppercase mb-2">{ t.trans('campaign.donate-now') }</div>
                    <div className={'text-sm lg:text-lg'}>
                        <MoneyRows />
                    </div>
                    <div>

                        <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm">{CurrencyType[methods.getValues('currency') ?? 'USD'].Symbol}</span>
                            </div>
                            <input
                                {...methods.register('amount', { required, ...validations })}
                                type="text"
                                placeholder="0.00"
                                className={classNames("border-2 border-gray-300 px-8 py-2 text-xl text-blue-500 w-full rounded-lg", methods.getValues('currency') != 'USD' ? 'pl-10' : '')}
                            />
                            <CurrencySelect />
                        </div>
                    </div>

                    {money(methods.getValues('amount'), methods.getValues('currency')).dollars() > 0 && (
                    <div className="mb-6">
                        <p className={'mt-4 mb-8 text-left text-xs text-gray-600'} dangerouslySetInnerHTML={{__html: t.trans('campaign.fee') }} ></p>
                        {!hasCustomTip &&
                        <Controller control={methods.control} name={'tip'} defaultValue={15} render={({ field: { value, onChange } }) => (
                            <Box sx={{ padding: '0 10px' }}>
                                <Slider
                                    disabled={hasCustomTip}
                                    track={false}
                                    max={30}
                                    value={parseInt(value ?? 0)}
                                    onChange={onChange}
                                    aria-label="Always visible"
                                    step={5}
                                    marks={marks}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={value => <div className="text-xs">{ methods.getValues('tip') }%: { tipAmount() }</div>}
                                />
                            </Box>
                        )}
                        />
                        }
                        <div className={'text-xs text-center'}>
                            <span onClick={useCustomTip} className={'cursor-pointer text-tukios-navy-light underline'}>{hasCustomTip ? t.trans('campaign.custom-tip-no') : t.trans('campaign.custom-tip')}</span>
                            {hasCustomTip && (
                                <Input
                                    submitErrors={errors}
                                    inputClassNames={'text-xl p-0 py-2 pr-3'}
                                    name="custom_tip"
                                    prefix={CurrencyType[campaign.currency].Symbol}
                                    key={"custom_tip"}
                                    type="text"
                                    className="text-sm lg:text-3xl block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                                    placeholder="$15.00"
                                />
                            )}
                        </div>
                    </div>
                    )}


                    <div className="mb-2">
                        <Input submitErrors={errors} placeholder={t.trans('campaign.name')} name="name" required/>
                    </div>

                    <div className="mb-2">
                        <Input submitErrors={errors} placeholder={t.trans('campaign.email')} name="email" required/>
                    </div>


                    <div className="mb-6 text-left">
                        <Toggle tiny={true} label={t.trans('campaign.anon')} labelClass="text-xs text-gray-600" state={anonymous} action={setAnonymous}/>
                    </div>

                    <div className="flex justify-center">
                        <button type="submit"
                                className="px-5 py-2 border border-transparent uppercase
                            font-medium rounded-full shadow-sm text-white bg-blue-500 hover:bg-blue-600
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
                            { t.trans('campaign.donate' )} {getTotal()}
                        </button>
                    </div>

                </div>


            </form>
        </FormProvider>
    )
}
